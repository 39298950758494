.collections-container .MuiBox-root {
  margin-top: 25px;
  padding: 0 150px;
}

.collections-container .MuiBox-root .Mui-active,
.collections-container .MuiBox-root .Mui-completed {
  color: #009600;
}


.collections-container .step-form {
  position: relative;
  margin-top: 18px;
  min-height: 48vh;
}

.collections-container .step-form .group-form,
.collections-container .group-form {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.collections-container .step-form .group-form label,
.collections-container .group-form label {
  display: block;
  color: #222;
  font-size: 14px;
  font-weight: 500;
}

.collections-container .step-form .group-form input,
.collections-container .step-form .group-form textarea,
.collections-container .group-form input,
.collections-container .group-form textarea {
  width: 100%;
  padding: 10px;
  color: #525f7f;
  border: 1px solid #525f7f50;
  border-radius: 10px;
  resize: none;
}

.collections-container .step-form .col-xl-8 {
  padding: 0;
}

.collections-container .group-form.image-upload-group .df.jcc .poupup-img {
  width: 105px;
}
.collections-container .group-form.image-upload-group img.popup-img {
  width: 100%;
  height: 175px;
  object-fit: cover;
  border-radius: 9px;
}
.collections-container .step-form .group-form.image-upload-group,
.collections-container .group-form.image-upload-group {
  height: 158px;
  width: 100%;
  padding: 0 !important;
  border-radius: 9px;
  border: none;
  resize: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #525f7f50;
  overflow: hidden;
}

.collections-container .step-form .group-form.image-upload-group input, 
.collections-container .group-form.image-upload-group input {
  position: absolute;
  left: 0;
  top: 0;
  height: 157px;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.collections-container .step-form .group-form.image-upload-group .close-popup,
.collections-container .group-form.image-upload-group .close-popup {
  position: absolute;
  right: 11px;
  top: 8px;
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  z-index: 99;
}

.collections-container .step-form .group-form.image-upload-group label,
.collections-container .group-form.image-upload-group label{
  color: #000 !important;
}

.collections-container .MuiBox-root .MuiButton-textPrimary {
  background-color: #009600 !important;
  color: #fff;
  padding: 10px 35px;
  border-radius: 34px;
}