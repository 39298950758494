.mint-new {
  display: flex;
  justify-content: center;
}

.crowd-stage-drawer .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  width: 800px !important;
}

.main-container .mint-new-NFT {
  display: flex;
  text-align: center;
  align-items: left;
  justify-content: center;
}

.edit-add p {
  text-align: left;
}

.MuiButtonBase-root {
  display: flex !important;
}
.main-modal.allowlist-modal .modal-content .modal-body .select-option {
  margin-bottom: 20px;
}

.edit-add-buttons.col-sm-12.mt-4 {
  text-align: center;
}

.edit-add-buttons.col-sm-12.mt-4 button {
  padding: 11px 20px;
  margin-right: 5px;
  font-size: 13px;
  border-radius: 13px;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1.2px;
  background-color: #009600 !important;
  border: 1px solid #009600 !important;
  color: #fff;
}
.main-container .collections-container .toggle-button-group button{
  padding: 11px 20px;
  margin-right: 5px;
  font-size: 13px;
  border-radius: 13px;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1.2px;
}
.edit-add-buttons.col-sm-12.mt-4 button:hover,
.main-container .collections-container .toggle-button-group button:hover {
  box-shadow: 0 0 5px rgba(0 0 0 /40%);
}
.main-container .collections-container .toggle-button-group button svg, 
.main-container .collections-container .toggle-button-group button svg path{
  color: #fff;
  fill: #fff
}
.main-container .collections-container .toggle-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 7px 15px 0px;
}

.main-container .collections-container .toggle-button-group h3 {
  margin-bottom: 0;
  font-size: 20px;
}
.main-container .mint-new-NFT .MuiButton-textPrimary {
  background-color: #009600 !important;
  background-image: linear-gradient(to bottom left, #009600, #009600, #009600) !important;
  background-image: -moz-linear-gradient(to bottom left, #009600, #009600, #009600) !important;
  color: #ffffff;
  box-shadow: none;
}

.edit-add svg.Mui-active,
.edit-add .Mui-completed {
  color: #009600 !important;
}

.edit-add-field label {
  display: block;
}

.edit-add-field .react-datepicker-wrapper {
  width: 100%;
}

.edit-add-field .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%;
  padding: 15px 15px;
  border: 1px solid #6c757d30;
  border-radius: 6px;
}

.edit-add-field {
  text-align: left;
}

.edit-add-field {
  margin-bottom: 20px;
}

.edi.edit-add-field {
  margin-bottom: 20px;
}

t-add-field label {
  text-align: left !important;
}

.main-container .mint-new-NFT .MuiButton-textPrimary:hover {
  box-shadow: 2px 1px 5px rgba(0 0 0 / 60%);
}

.css-tlelie-MuiListItemText-root {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.loader {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #00000030;
  text-align: center;
  backdrop-filter: blur(5px);
}

.loader .loader-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.collections-container .step-form .edit-add-field {
  width: 100%;
}

.collections-container .step-form .edit-add-buttons {
  text-align: center;
}

.collections-container .step-form .edit-add-buttons .btn.btn-primary {
  border-radius: 10px !important;
}

.collections-container .step-form .edit-add-buttons .btn.btn-primary:hover {
  background-color: #006e00 !important;
}

.collections-container .MuiBox-root button.MuiButton-root:disabled {
  display: none !important;
}

.select-option .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  width: 300px !important;
}

.select-option .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #006e00 !important;
}