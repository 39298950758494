
hr {
  width: 100%;
}

p {
  color: #000000 !important;
}

.Toastify__toast--success {
  background: #14A8A6;
}

/* ========== Custom Scroll Styles =========== */

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0c0f0b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c0f0b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0c0f0b;
}


/* ========== Custom MainContainer Styles =========== */

.main-container {
    background-color: #ffffff !important;
    padding: 30px 20px !important;
    border-radius: 4px !important;
    box-shadow: 0 3px 8px -3px #00000030 !important;
    min-height: 90vh;
}

.main-container-head {
  display: flex;
  justify-content: space-between;
}

.main-container-heading {
  color: #000000;
  font-size: 18px;
  font-weight: 550;
  margin-top: 10px;
}

/* ========== Custom Table Styles =========== */

.table .rt-tbody .rt-td {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ========== Custom Slider Styles =========== */

.slider {
  padding: 30px 40px;
  width: 1175px;
}

/*========== Custom Styles View Data  ============= */

.view-data .view-data-main-title {
  font-weight: 500;
  font-size: 26px;
  padding-bottom: 5px;
}

.view-data .view-data-body .view-data-row {
  background-color: #eaeded  ;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
}

.view-data .view-data-body .view-data-row .view-data-title {
  font-weight: 600;
}

.view-data .view-data-body .view-data-sub-title {
  font-size: 20px;
  font-weight: 600;
}

.view-data .view-data-footer {
  display: flex;
  justify-content: center;
}


/*========== Custom Styles Edit-Add Slider ============= */

.edit-add {
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
}

.edit-add .edit-add-title {
font-size: 26px;
font-weight: 500;
text-align: left;
}

.edit-add .edit-add-sub-title {
  font-size: 20px;
  font-weight: 500;
  text-align: start;
  }

.edit-add .edit-add-body .edit-img {
  width: 120px; 
  height: 120px; 
  border-radius: 50%;
  background: 'gray';
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiFormLabel-root.Mui-focused {
  color: #009600;
}

.edit-add .edit-add-body .form.edit-add-field .form-input-field
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid #009600;
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.MuiAutocomplete-option {
  color: #000000;
}

.edit-add .edit-add-body .edit-add-buttons {
display: flex;
padding: 30px 90px;
justify-content: center;
}

/* ========== Sidebar Styles =========== */


.sidebar nav {
  margin-top: 30px;
}

.sidebar nav ul {
  margin-bottom: 0;
  list-style: none;
}

.sidebar nav ul li {
  position: relative;
}

.sidebar nav ul li .nav-link {
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  color: #0c0f0b !important;
}

.sidebar nav ul li .nav-link p {
  margin-bottom: 0;
  margin-left: 16px;
  transition: all 0.3s ease-in-out;
  color: #0c0f0b !important;
}

.sidebar nav ul li .nav-link i {
  color: #0c0f0b !important;
  transition: all 0.3s ease-in-out;
}

.sidebar nav ul li .nav-link:hover, 
.sidebar nav ul li .nav-link:hover p, 
.sidebar nav ul li .nav-link:hover i,
.sidebar nav ul li .nav-link.active,
.sidebar nav ul li .nav-link.active p,
.sidebar nav ul li .nav-link.active i {
  color: #009600 !important;
}
.edit-add-body .edit-add-buttons button {
  background-color: #009600;
  color: #fff;
  transition: all 0.3s ease-in-out;
  padding: 10px 15px;
}

.edit-add-body .edit-add-buttons button:hover {
  background-color: #006e00;
}
.footer{
  background-color: #f5f5f5 !important;
}
.footer .copyright {
  color: #000 !important;
}
.edit-add-body {
  margin-top: 26px;
}
.footer .copyright a {
  color: #009600 !important;
}
.login-page {
  background-color: #f5f5f5 !important;
}
/* ========== Custom Button Styles =========== */


.add-btn {
  color: #ffffff !important;
  padding: 5px 15px !important;
  border-radius: 10px !important;
  border: 1px solid #0c0f0b !important;
  background-color: #0c0f0b !important;

}

.add-btn:hover {
  color: #ffffff !important;
  background-color: #009600 !important;
  border: 1px solid #009600 !important;
}

.view-btn {
  min-width: 45% !important;
  color: #009600 !important;
  border-radius: 10px !important;
  border: 1px solid #0c0f0b !important;
}

.view-btn:hover {
  color: #ffffff !important;
  background-color: #009600 !important;
}

.edit-btn {
  min-width: 45% !important;
  color: #009600 !important;
  border-radius: 10px !important;
  border: 1px solid #0c0f0b !important;
}

.edit-btn:hover {
  color: #ffffff !important;
  background-color: #009600 !important;
}

.delete-btn {
  min-width: 45% !important;
  color: #e74c3c !important;
  border-radius: 10px !important;
  border: 1px solid #e74c3c !important;
}

.delete-btn:hover {
  color: #ffffff !important;
  background-color: #e74c3c !important;
}

.cancel-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #e74c3c  !important;
}

.submit-btn {
    border-radius: 10px !important;
    color: #ffffff !important;
    background: #009600  !important;
}

.confirm-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #009600  !important;
}

Button:focus {
    outline: 0px !important; 
}

/* ========== Custom AppLoader Styles =========== */

.loader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  color: #009600;
}

.blue-loader {
  color: #009600;
}

/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 500px !important;
  top: 0;
}

.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  background-color: #fff !important;
  border-radius: 30px !important;
}

.main-modal .modal-content .modal-header {
  display: flex;                   
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.main-modal .modal-content .modal-header .close {
  color: #000 !important;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 32%;
  padding: 10px;
  margin: 35px 0px 15px;
  border-radius: 10px;
}
.main-modal .modal-content h2 {
  font-size: 24px;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
}
.create-collection-modal .modal-logo {
  flex-wrap: wrap;
  border-bottom: 1px solid #00000020;
}

.modal-content .modal-header .modal-title{
  width: 100%;
}

.create-collection-modal .modal-logo h2 {
  width: 100%;
  text-align: center;
}

.main-modal.allowlist-modal .modal-content .modal-body .group-form {
  position: relative;
  margin-bottom: 20px;
}
.modal-dialog.main-modal.allowlist-modal {
  transform: translate(0, -50%);
  top: 50%;
}

.main-modal.allowlist-modal .modal-content .modal-body .group-form span {
  color: #000;
  font-size: 13px;
  margin-bottom: 11px;
  display: block;
}

.main-modal.allowlist-modal .modal-content .modal-body .group-form .MuiInput-root {
  border: 1px solid #ccc;
  border-radius: 8px;
}

.main-modal.allowlist-modal .modal-content .modal-body .group-form .MuiInput-root:before, .main-modal.allowlist-modal .modal-content .modal-body .group-form .MuiInput-root:after {
  display: none;
}

.modal-dialog.main-modal.allowlist-modal {
  transform: translate(0, -50%) !important;
  top: 50%;
}
.main-modal .modal-content h4 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.main-modal.allowlist-modal .modal-content .modal-body .group-form label {
  display: block;
  width: 100%;
}

.main-modal.allowlist-modal .modal-content .modal-body .group-form input, 
.main-modal.allowlist-modal .modal-content .modal-body .group-form textarea {
  width: 100%;
  padding: 13px 15px;
  border-radius: 6px;
  background-color: #ffffff99;
  resize: none;
  
}
.collections-container .MuiBox-root .MuiFormControl-root label#demo-simple-select-label {
  background-color: #fff;
}
.main-modal.allowlist-modal .modal-content .modal-body .group-form .btn-primary,
.main-modal.allowlist-modal .modal-content .modal-body .group-form .submita-btn {
  background: #006e00;
  background-image: linear-gradient(to bottom left, #006e00, #006e00, #006e00);
  background-size: 210% 210%;
  background-position: top right;
  background-color: #006e00;
  transition: all 0.3s ease-in-out !important;
}
.main-modal.allowlist-modal .modal-content .modal-body .group-form .btn-primary:hover,
.main-modal.allowlist-modal .modal-content .modal-body .group-form .submita-btn:hover{
  background: #0c0f0b !important;
  background-image: linear-gradient(to bottom left, #0c0f0b, #0c0f0b, #0c0f0b) !important;
  background-color: #0c0f0b;
}
.modal.show .modal-dialog.metadata-modal {
  transform: translate(10px, 10px);
}

.modal-dialog.main-modal.allowlist-modal.create-collection-modal.metadata-modal {
  max-width: 630px !important;
}
.main-modal.allowlist-modal .modal-content .modal-body .group-form.plus-btn button {
  padding: 10px 28px;
  background: #006e00 !important;
}
.create-collection-modal .modal-content .modal-header .modal-logo img {
  width: 200px;
  padding: 10px;
  margin: 35px 0px 15px;
}
.main-modal .modal-content .modal-body {
  padding: 0px;
}



/*========== Responsive ============= */

@media only screen and (max-width: 650px){

  .slider {
      padding: 30px;
      width: 500px;
  }

  .slider .edit-add {
      padding: 20px 10px;
  }

  .slider .edit-add .slider-body .edit-add-buttons {
      padding: 30px;
  }
}

@media only screen and (max-width: 425px){
  .slider {
      padding: 20px;
      width: 400px;
  }
}

@media only screen and (max-width: 375px){
  .slider {
      padding: 10px;
      width: 325px;
  }
}
