.user-view-btn {
  display: flex;
  justify-content: center;
  padding: 4px;
}



/* ========== Custom Styles Allowlist Modal =========== */


.main-modal.allowlist-modal .modal-content .modal-body {
  padding: 10px 50px;
}

.whitelist-modal .modal-content .modal-header hr {
  border-top: 1px solid #ffffff;
}

.whitelist-modal .text-field .MuiFormLabel-root.Mui-focused,
.whitelist-modal .text-field .MuiFormLabel-root,
.whitelist-modal .text-field .MuiInputBase-root,
.whitelist-modal .text-field .MuiFormHelperText-root {
  color: rgb(255, 255, 255) !important;
}

.whitelist-modal .text-field .MuiInput-underline:after,
.whitelist-modal .text-field .MuiInput-underline:before,
.whitelist-modal .text-field .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #ffffff;
}
.action-buttons .add-btn {
  padding: 8px 15px !important;
  margin: 0px 3px !important;
  background-color: #009600 !important;
  border: 1px solid #009600 !important;
}
.action-buttons .add-btn:hover{
  background-color: #006e00 !important;
  border: 1px solid #006e00 !important;
}
.MuiButton-outlined {
  margin: auto 3px !important;
}
.delete-button {
  color: #e05656 !important;
  border: 1px solid rgba(181, 63, 63, 0.5) !important;
  border-radius: 5px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}

.delete-button:hover {
    background-color: rgba(181, 63, 63, 0.04) !important;
}

.edit-button {
  color: #3f51b5 !important;
    border: 1px solid rgba(63, 81, 181, 0.5);
  border-radius: 5px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}
.table .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td a.edit-button {
  margin-right: 10px;
}
.edit-button:hover {
  border: 1px solid #3f51b5;
  background-color: rgba(63, 81, 181, 0.04);
}

.ReactTable {
  border: none !important;
}


.ReactTable .rt-tbody .rt-td {
  border-right: 2px solid #f5f5f5 !important;
}

.pagination-bottom {
  background-color: rgba(0, 0, 0, 0.30);
  border-radius:0  0 9px 9px;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr.-even .rt-td {
  background-color: #f5f5f550 !important;
}

.table .rt-th .rt-resizable-header-content {
  background-color: #f5f5f5;
  color: #000;
  font-size: 14px;
  padding: 11px;
  border-radius: 5px;
  text-align: center;
  min-height: 43px;
}


.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select,
.ReactTable .-pagination .-pageJump input,
.ReactTable .-pagination input,
.ReactTable .-pagination select {
  background: #f5f5f570 !important;
  padding: 12px 15px !important;
  color: #000;
  border-radius: 3px;
  text-align: left;
  font-size: 14px !important;
  border: none !important;
  margin-bottom: 6px;
}

.ReactTable .-pagination .-pageInfo {
  color: #fff !important;
}

.table .rt-tbody .rt-tr-group {
  border: none;
  border-radius: 5px !important;
}

.table .rt-th .rt-th .rt-th-input {
  background-color: gold;
  color: navy;
}

.table .rt-table .rt- .rt-tbody {}

.table .rt-table .rt-tbody {
    width: 100%;
}

.table .rt-table .rt-tbody .rt-tr-group {width: 100%;}

.table .rt-table .rt-tbody .rt-tr-group .rt-tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
    font-size: 13px !important;
    padding: 12px;
    min-height: 48px;
    text-align: center !important;
    justify-content: center;
}

.table .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child {
    text-align: left !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    justify-content: flex-start;
}
.table .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td button, 
.table .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td a {
  font-size: 12px !important;
  padding: 5px 12px;
  text-transform: capitalize;
}

.table .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td button span {
  font-size: 10px !important;
}

.ReactTable .-pagination .-pageInfo {
  color: #fff !important;
}

.ReactTable .-pagination .-btn {
  color: #fff !important;
}


.pagination-bottom .-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 5px !important;
}

.pagination-bottom .-pagination .-previous, .pagination-bottom .-pagination .-next {
  width: 50%;
}

.pagination-bottom .-pagination .-center {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 15px;
  justify-content: center;
}

.pagination-bottom .-pagination .-previous .-btn, .pagination-bottom .-pagination .-next .-btn {
  width: 100%;
  padding: 11px;
  background-color: #009600;
  border: none;
}

.pagination-bottom .-pagination .-center .-pageInfo {position: relative;margin-right: 61px;display: flex;align-items: center;}

.pagination-bottom .-pagination .-center .-pageInfo .-pageJump {padding: 0 19px;}

.pagination-bottom .-pagination .-center .-pageSizeOptions select {
  width: 150px !important;
  background: #f5f5f5 !important;
}

.pagination-bottom .-pagination .-center .-pageInfo .-pageJump input[type="number"] {
  width: 100px;
  background: #f5f5f5 !important;
}