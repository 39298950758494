.mint-new {
  display: flex;
  justify-content: center;
}

.main-container .mint-new-NFT {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.main-container.mint-new .mint-new-NFT{
  align-items: flex-start;
}
.MuiButtonBase-root {
  display: flex !important;
}

.css-tlelie-MuiListItemText-root {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.loader {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #00000030;
  text-align: center;
  backdrop-filter: blur(5px);
}

.loader .loader-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}