.main-container.mint-new .collections-container {
  width: 100%;
}
.main-container.mint-new .collections-container .back-btn {
  background-color: transparent;
  border: none;
}

.main-container.mint-new .collections-container .back-btn img {
  width: 31px;
}

.collections-container .MuiBox-root {
  margin-top: 25px;
}
.collections-container .MuiBox-root .Mui-active,
.collections-container .MuiBox-root .Mui-completed {
  color: #009600;
}


.collections-container .step-form {
  position: relative;
  margin-top: 18px;
  min-height: 48vh;
}
.collections-container .content .main-container {
  padding: 0 !important;
  box-shadow: none !important;
}

.collections-container .step-form .group-form {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.collections-container .step-form .group-form label {
  display: block;
  color: #525f7f;
  font-size: 14px;
  font-weight: 500;
}
.collections-container .collections-header.collection-header {justify-content: flex-start;}

.collections-container .collections-header.collection-header a {
    left: -10px;
}

.collections-container .collections-header.collection-header a {
    font-size: 14px;
    padding: 7px 15px;
}
.collections-container .step-form .group-form input,
.collections-container .step-form .group-form textarea {
  width: 100%;
  padding: 10px;
  color: #525f7f;
  border: 1px solid #525f7f50;
  border-radius: 10px;
  resize: none;
}

.collections-container .step-form .col-xl-8 {
  padding: 0;
}

.collections-container .step-form .group-form.image-upload-group {
  height: 158px;
  width: 100%;
  padding: 10px;
  border-radius: 9px;
  border: none;
  resize: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #525f7f50;
}

.collections-container .step-form .group-form.image-upload-group input {
  position: absolute;
  left: 0;
  top: 0;
  height: 115px;
  z-index: 1;
  opacity: 0;
}
.collections-container .step-form .group-form.image-upload-group .close-popup {
  position: absolute;
  right: 11px;
  top: 8px;
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  z-index: 99;
}
.collections-container .step-form .group-form.image-upload-group label {
  color: #000 !important;
}

.collections-container .MuiBox-root .MuiButton-textPrimary,
.login-page button {
  background-color: #009600 !important;
  color: #fff;
  padding: 10px 35px;
  border-radius: 8px;
}
.collections-container .collections-header.collection-header .back-btn {
  border: none;
  background-color: transparent;
  margin-right: 8px;
}
.collections-container .collections-header.collection-header .back-btn img {
  width: 30px;
}
.collections-container .MuiBox-root .MuiButton-textPrimary{
  border-radius: 10px;
}
.collections-container .MuiBox-root .MuiButton-textPrimary:hover,
.login-page button:hover{
  background-color: #006e00 !important;
}
.collections-container .group-form.image-upload-group video.popup-img.brw5.tac {
  width: 100%;
  height: 183px;
  top: -7px;
  position: relative;
  border-radius: 8px;
}
.collections-container .group-form.image-upload-group video.popup-img.show-img.brw5.tac {
  position: absolute;
  left: 0;
  top: -20px;
}
.collections-container .group-form.image-upload-group img.popup-img.show-img {
  position: absolute;
  left: 0;
}
.collections-container .group-form.id-group {
  display: flex;
  align-items: center;
}
.collections-container .group-form.id-group input#id {
  width: 35%;
}
.collections-container .group-form.id-group label {
  margin-bottom: 0;
  margin-right: 19px;
}
.collections-container .group-form.plus-btn {
  padding-left: 40px;
}

.collections-container .group-form.plus-btn .plus-btn {
  position: absolute;
  left: -1px;
  height: 43px;
  width: 42px;
  border: 1px solid #525f7f50;
  background-color: transparent;
  border-radius: 8px 0 0 8px;
  z-index: 2;
  font-size: 22px;
  color: 009600;
}

.collections-container .group-form.plus-btn input {border-radius: 0 8px 8px 0;}

.collections-container .group-form.plus-btn label {
  margin-left: -34px;
}

.collections-container .group-form.delete-remove-btn .remove-btn {
  position: absolute;
  right: -1px;
  height: 43px;
  width: 42px;
  border: 1px solid #525f7f50;
  background-color: transparent;
  border-radius: 0 8px 8px 0;
  z-index: 2;
  font-size: 22px;
}

.collections-container .group-form.delete-remove-btn {
  padding-right: 41px;
}

.collections-container .group-form.delete-remove-btn input {
  border-radius: 8px 0 0 8px;
}

.collections-container .group-form.delete-remove-btn .remove-btn svg {
  width: 22px;
  fill: red;
}

.main-container.collection-container-main {
  min-height: 57vh;
}
.main-container.collection-container-main .main-container {
  min-height: 57vh;
}
.collection-detail-page {
  margin: 31px 0 0;
}

.collection-detail-page .group-form {
  position: relative;
}
.collection-detail-page .group-form span {
  background-color: #f5f5f5;
  color: #000;
  font-size: 14px;
  padding: 11px;
  border-radius: 5px;
  min-height: 43px;
  width: 100%;
  display: block;
}